<template>
  <FormInput
    :label="label"
    :id="id"
    :name="name"
    :value="input"
    @input="handleChange"
    :error="error_label"
    :placeholder="placeholder"
    :formGroupStyle="'margin-bottom: 0px !important;'"
  >
    <template #input="{ id, input_name: name, value : inpt_value, handleInput, placeholder }">
      <b-form-input
        :id="id"
        type="search"
        :name="name"
        :value="inpt_value"
        :placeholder="placeholder"
        :aria-placeholder="placeholder"
        role="combobox"
        @input="handleInput"
        :state="error"
        :aria-expanded="showOptions"
        aria-autocomplete="list"
        :aria-activedescendant="optionHover"
        aria-owns="listbox"
        dir="auto"
        autocomplete="off"
        aria-label="Pesquisar"
      />
        <section
          id="listbox"
          role="listbox"
          v-click-outside="onClickOutside"
          :class="listbox_class"
        >
          <div class="option-hover" v-if="!options.length && !input.length">
            <span>
              Carregando...
            </span>
          </div>
          <div class="option-hover" v-if="!options.length && !!input.length">
            <span>
              Nenhum banco encontrado para a pesquisa
            </span>
          </div>
          <div
            :id="'option-' + option.value"
            role="option"
            class="option-hover"
            v-for="option in options"
            :key="option.value"
            @click="handleSelect(option)"
            @mouseover="optionHover = 'option-' + option.value"
          >
          <span>{{ option.text }}</span>
        </div>
        </section>
    </template>
  </FormInput>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: [Boolean],
      required: false,
    },
    error_label: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validate: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  $_veeValidate: {
    value() {
      return this.value;
    },
    name() {
      return this.name;
    }
  },
  data() {
    return {
      selectedOption: this.value || {},
      showOptions: false,
      input: '',
      optionHover: '',
    };
  },
  computed: {
    listbox_class() {
      const classes = ['listbox'];
      if (this.showOptions) {
        classes.push('show');
      }
      return classes.join(' ');
    },
  },
  watch: {
    value: {
      handler(receivedValue) {
        if (receivedValue && receivedValue.text && receivedValue.text !== this.input) {
          this.input = receivedValue.text;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelect(value) {
      this.$emit("input", value);
      this.showOptions = false;
    },
    handleChange(text) {
      this.input = text;
      this.showOptions = true;
      this.$emit("input", null);
      this.$emit("search", text);
    },
    onClickOutside() {
      this.showOptions = false;
    },
  },
  components: {
    FormInput: () => import("@/components/shared/forms/FormInputGeneric.vue"),
  },
}
</script>
<style lang="scss" scoped>
  .listbox {
    position: absolute;
    top: 100%;
    z-index: 1000;
    left: 0px;
    right: auto;
    display: none;
    width: -webkit-fill-available;
    background-color: #F8F8F8;
    padding: 1em 0em;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: #0e0e0e;
    border-radius: 5px;
    overflow-y: scroll;
    max-height: 30vh;
  }
  .show {
    display: block;
  }
  .option-hover {
    padding: 0.5em 1em;
  }
  .option-hover:hover {
    background-color: var(--blue);
    cursor: pointer;
  }
  .option-hover:hover span {
    color: #fff;
  }
  .formGroupClass {
    margin-bottom: 0px !important;
  }
  
</style>